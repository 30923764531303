import React, { FC } from "react";
import * as S from "./ServiceDescription.styles";

export const ServiceDescription: FC<ServiceDescriptionProps> = ({
	description,
	image,
	title,
}) => {
	return (
		<S.ServiceDescriptionContainer>
			<S.Title>{title}</S.Title>
			<S.Description>{description}</S.Description>
			<S.ImageContainer>
				<img src={image} alt="service" />
			</S.ImageContainer>
		</S.ServiceDescriptionContainer>
	);
};

export type ServiceDescriptionProps = {
	title: string;
	description: string;
	image: string;
};
