import React, { FC } from "react";
import * as S from "./ImageCard.styles";

export const ImageCard: FC<ImageCardProps> = ({ image, isLandscape }) => {
	return (
		<S.ImageCardContainer isLandscape={isLandscape}>
			<img src={image} alt="card" />
		</S.ImageCardContainer>
	);
};

type ImageCardProps = {
	isLandscape: boolean;
	image: string;
};
