import React, { FC } from "react";
import * as S from "./ContactSection.styles";

export const ContactSection: FC = () => {
	return (
		<S.ContactSectionContainer>
			<S.Title>Pregatit sa incepi?</S.Title>
			<S.Description>
				Daca esti interesat in serviciile noastre si doresti informatii
				aditionale.
			</S.Description>
			<S.Button
				target="_blank"
				href="https://api.whatsapp.com/send?phone=%2B40747223511&app=facebook&entry_point=page_cta"
			>
				Contacteaza-ne
			</S.Button>
		</S.ContactSectionContainer>
	);
};
