import styled from "styled-components/macro";
import { mediaQueries } from "../../../utils";

export const ServiceDescriptionContainer = styled("div")`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	@media ${mediaQueries.tablet} {
		padding-top: 3rem;
		text-align: center;
	}
`;

export const Title = styled("h1")`
	font-size: ${(props) => props.theme.fontSizes.large};
	@media ${mediaQueries.mobileL} {
		font-size: ${(props) => props.theme.fontSizes.xmedium};
	}
	@media ${mediaQueries.mobileS} {
		font-size: ${(props) => props.theme.fontSizes.medium};
	}
`;

export const Description = styled("p")`
	font-size: ${(props) => props.theme.fontSizes.medium};
	@media ${mediaQueries.mobileS} {
		font-size: ${(props) => props.theme.fontSizes.small};
	}
`;
export const ImageContainer = styled("div")`
	box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
		rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
		rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;
