import React from "react";
import * as S from "./App.styles";
import { AppRouting } from "./routes/AppRouting";
import Theme from "./Theme";
import { HelmetProvider } from "react-helmet-async";

function App() {
	return (
		<Theme>
			<HelmetProvider>
				<S.GlobalStyles />
				<AppRouting />
			</HelmetProvider>
		</Theme>
	);
}

export default App;
