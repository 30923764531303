import styled from "styled-components/macro";
import { CenteredDivOnBothAxis } from "../../../../utils";

interface IBurgerStateProps {
	cssShowSideBar: boolean;
}

export const BurgerContainer = styled(CenteredDivOnBothAxis)`
	flex-direction: column;
	gap: 0.7rem;
	margin-right: 2.844rem;
	height: 100%;
`;

const Line = styled("span")<IBurgerStateProps>`
	width: 3rem;
	height: 0.3rem;
	background-color: ${(props) => props.theme.colors.black};
	transition: all 1s cubic-bezier(1, 0.03, 0.4, 1.46);
	${(props) => props.cssShowSideBar && "border-radius:0.3rem;"};
`;

export const Line1 = styled(Line)<IBurgerStateProps>`
	${(props) =>
		props.cssShowSideBar &&
		"transform:rotateZ(58deg) translateX(0.8rem) translateY(0.7rem);width:3.5rem;"}
`;
export const Line2 = styled(Line)<IBurgerStateProps>`
	${(props) =>
		props.cssShowSideBar &&
		"transform:rotateZ(90deg) translateX(0.1rem) translateY(1rem)"}
`;
export const Line3 = styled(Line)<IBurgerStateProps>`
	${(props) =>
		props.cssShowSideBar &&
		"width:2rem;transform:translateX(-0.1rem) translateY(0.5rem)"}
`;
