import React, { FC, useEffect, useRef, useState } from "react";
import * as S from "./Header.styles";
import { Logo } from "./Logo/Logo";
import { ContactWidget } from "./ContactWidget/ContactWidget";
import { NavLinks } from "./NavLinks/NavLinks";
import { useWindowSize } from "../../../hooks";
import { HamburgerButton } from "./HamburgerButtom/HamburgerButton";
import { MobileSideBar } from "./MobileSideBar/MobileSideBar";

export const Header: FC = () => {
	const [showSideBar, setShowSideBar] = useState<boolean>(false);
	const { width } = useWindowSize();

	const sideBarRef = useRef<HTMLDivElement>(null);
	const hamburgerButtonRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleOutsideClick = (event: any) => {
			if (
				sideBarRef.current &&
				hamburgerButtonRef.current &&
				!sideBarRef.current.contains(event.target) &&
				!hamburgerButtonRef.current.contains(event.target)
			) {
				setShowSideBar(false);
			}
		};

		if (showSideBar) {
			document.addEventListener("mousedown", handleOutsideClick);
		}

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [showSideBar]);

	if (width > 768)
		return (
			<S.NavbarContainer>
				<Logo />
				<NavLinks />
				<ContactWidget />
			</S.NavbarContainer>
		);
	else {
		return (
			<S.NavbarContainer>
				<Logo />
				<HamburgerButton
					setShowSideBar={setShowSideBar}
					showSideBar={showSideBar}
					hamburgerButtonRef={hamburgerButtonRef}
				/>
				<MobileSideBar
					setShowSideBar={setShowSideBar}
					showSideBar={showSideBar}
					sideBarRef={sideBarRef}
				/>
			</S.NavbarContainer>
		);
	}
};
