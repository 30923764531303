import styled from "styled-components/macro";
import { mediaQueries } from "../../../utils";
import { Link } from "react-router-dom";

export const GridCellContainer = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;

	flex-direction: column;
	gap: 1rem;
	width: 80%;
	margin: 0 auto;
	@media ${mediaQueries.laptop} {
		width: 100%;
	}
	@media ${mediaQueries.tablet} {
		gap: 0.2rem;
	}
`;

export const IconContainer = styled("div")`
	width: 8rem;
	height: 8rem;
	img {
		width: 100%;
		height: 100%;
	}
	@media ${mediaQueries.tablet} {
		width: 5rem;
		height: 5rem;
	}
`;

export const Title = styled("h3")`
	font-size: ${(props) => props.theme.fontSizes.xmedium};
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	color: ${(props) => props.theme.colors.white};
	font-weight: bold;
	text-align: center;
	@media ${mediaQueries.mobileL} {
		font-size: 1.8rem;
	}
`;

export const Description = styled("p")`
	font-size: ${(props) => props.theme.fontSizes.medium};
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	color: ${(props) => props.theme.colors.black};
	line-height: ${(props) => props.theme.fontSizes.xmedium};
	text-align: center;
	font-weight: bold;
	@media ${mediaQueries.mobileL} {
		font-size: 1.4rem;
	}
`;
