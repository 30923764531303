import styled from "styled-components/macro";
import { mediaQueries } from "../../../utils";

interface ITextBlocCentered {
	isCentered: boolean;
}

export const TextBlockContainer = styled("div")<ITextBlocCentered>`
	display: flex;
	flex-direction: column;
	align-items: ${(props) => (props.isCentered ? "center" : "flex-start")};
	max-width: 40vw;
	@media ${mediaQueries.tablet} {
		max-width: 80vw;
		height: auto;
	}
`;

export const IconContainer = styled("div")<ITextBlocCentered>`
	width: 7.8rem;
	height: 6.7rem;
	margin-left: ${(props) => (props.isCentered ? "0rem" : "5rem")};
	img {
		width: 100%;
		height: 100%;
	}
	@media ${mediaQueries.mobileM} {
		width: 5.8rem;
		height: 4.7rem;
	}
`;

export const Title = styled("h2")`
	font-size: ${(props) => props.theme.fontSizes.xlarge};
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	color: ${(props) => props.theme.colors.primaryGreen};
	letter-spacing: 2px;
	@media ${mediaQueries.laptop} {
		font-size: ${(props) => props.theme.fontSizes.large};
	}
	@media ${mediaQueries.mobileM} {
		font-size: ${(props) => props.theme.fontSizes.xmedium};
	}
`;

export const Description = styled("div")`
	font-size: ${(props) => props.theme.fontSizes.xmedium};
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	color: ${(props) => props.theme.colors.black};
	font-weight: bold;
	line-height: 4.5rem;
	@media ${mediaQueries.laptop} {
		font-size: ${(props) => props.theme.fontSizes.medium};
		line-height: 3.5rem;
		height: auto;
	}
	@media ${mediaQueries.mobileM} {
		line-height: 2.5rem;
	}
`;
