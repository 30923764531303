import React, { FC, useEffect, useRef, useState } from "react";
import * as S from "./Services.styles";
import { HelmetContainer, PageHeader } from "../../molecules";
import {
	BushService,
	CleanGarden,
	CleaningSpaces,
	Gradina,
	HomepageBannerPhoto,
	Irigation,
	LawnGarden,
} from "../../../assets/images";
import {
	ServiceButton,
	ServiceDescription,
	ServiceDescriptionProps,
} from "../../atoms";
import {
	BushOutlineIcon,
	FurtunIcon,
	GradenCareIcon,
	GreenGloveIcon,
	LawnMowerIcon,
	TreeIcon,
} from "../../../assets/icons";
import { useWindowSize } from "../../../hooks";
import { useSearchParams } from "react-router-dom";

export const Services: FC = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [searchParams] = useSearchParams();

	const urlService = searchParams.get("serviciuDeschis");

	const descriptionRefs = useRef<Array<HTMLDivElement | null>>([]);
	const ForwardedServiceDescription = React.forwardRef<
		HTMLDivElement,
		ServiceDescriptionProps
	>(({ title, description, image }, ref) => {
		return (
			<div ref={ref}>
				<ServiceDescription
					title={title}
					description={description}
					image={image}
				/>
			</div>
		);
	});

	const { width } = useWindowSize();
	const buttonsArray = [
		{
			icon: GreenGloveIcon,
			text: "Amenajare spații verzi",
			isSelected:
				urlService === null || urlService === "serviciu_1" ? true : false,
			key: "serviciu_1",
		},
		{
			icon: FurtunIcon,
			text: "Instalare Sisteme de irigații",
			isSelected: urlService === "serviciu_2" ? true : false,
			key: "serviciu_2",
		},
		{
			icon: TreeIcon,
			text: "Toaletare arbusti/gard viu",
			isSelected: urlService === "serviciu_3" ? true : false,
			key: "serviciu_3",
		},
		{
			icon: LawnMowerIcon,
			text: "Plantare gazon și întreținere",
			isSelected: urlService === "serviciu_4" ? true : false,
			key: "serviciu_4",
		},
		{
			icon: BushOutlineIcon,
			text: "Plantare garduri vii",
			isSelected: urlService === "serviciu_5" ? true : false,
			key: "serviciu_5",
		},
		{
			icon: GradenCareIcon,
			text: "Îngrijire spații verzi, parcuri, trotuare",
			isSelected: urlService === "serviciu_6" ? true : false,
			key: "serviciu_6",
		},
	];

	const servicesInfo: dataType = {
		serviciu_1: {
			text: `Indiferent dacă aveți nevoie de amenajarea unui mici  grădini, sau a unui parc extins, echipa noastră în ingrijirea spațiilor verzi vă stă la dispoziție. 
					Abordăm fiecare proiect cu atenție la detalii și creativitate, având în vedere nevoile și preferințele fiecărui client.
					Oferim o gamă variată de servicii: aplicare rulou gazon, gazonare prin insamantare cu seminte profesionale, tratamente foliare si solide pentru gazonul dvs, tratamente impotriva daunatorilor cat si buruienilor din, si-n imprejurul locatiei dvs , toaletare arbusti/garduri vii, mentinerea curateniei prin abonament, instalare  sisteme de irigații eficiente si mentenanta acestora.
					Echipa noastră utilizează cele mai bune practici și echipamente moderne pentru a asigura rezultate de înaltă calitate. 
					Indiferent dacă sunteți proprietarul unei case, administratorul unui complex rezidențial sau reprezentantul unei companii, vă garantăm că vom transforma spațiul dumneavoastră într-un loc atrăgător și plin de viață. 
					Ne asigurăm că fiecare proiect este finalizat la timp și în limitele bugetului.`,
			image: Gradina,
			title: "Amenajare spații verzi",
			key: "serviciu_1",
		},
		serviciu_2: {
			text: `Bun venit pe site-ul nostru dedicat serviciului de instalare a sistemelor de irigații! De ce un sistem de irigare?: acesta asigura o irigare  eficienta și automata pentru spații verzi/plante/arbusti etc… facilitându-vă îngrijirea acestora și menținerea lor într-o stare optimă de sănătate.
					Prin instalarea unui sistem  de irigare, acesta va ajuta să  castigati timp și favorizand consumul de apa, primind o cantitate de apa uniforma si exacta.
					Oferim servicii complete de instalare a sistemelor de irigatii.
					Folosim tehnologii moderne și produse de calitate pentru a ne asigura că fiecare zonă primește cantitatea potrivită de apă în mod eficient. 
`,
			image: Irigation,
			title: "Instalare Sisteme de irigații",
			key: "serviciu_2",
		},
		serviciu_3: {
			text: `Echipa noastră vă oferă servicii de toaletare arbusti/garduri vii, zone exterior asigurându-se că fiecare arbust este tratat cu atenție și pricepere.
					Indiferent dacă aveți nevoie de tăieri de îngrijire regulare, eliminarea crengilor periculoase sau remodelarea coroanei, suntem echipa potrivită pentru a face acest lucru într-un mod sigur și eficient. 
					Eliminăm crengile deteriorate sau bolnave, reducem riscul de cădere a ramurilor și eliminăm potențialele obstacole în jurul spațiului dumneavoastră verde.
					Lucrările sunt efectuate cu atenție, lăsându-vă un mediu curat și ordonat.`,
			image: HomepageBannerPhoto,
			title: "Toaletare arbusti/gard viu",
			key: "serviciu_3",
		},
		serviciu_4: {
			text: ` Indiferent dacă doriți să plantați un gazon nou sau să înlocuiți unul existent, vom lucra cu dumneavoastră pentru a selecta tipul potrivit de iarbă în funcție de condițiile de sol, expunerea la soare și utilizarea prevăzută a spațiului. 
					Folosim metode și tehnici profesionale pentru a asigura o plantare corectă și uniformă a gazonului, asigurându-ne că semințele sau bucățile de rulouri de iarbă sunt integrate perfect în sol.
					Ne preocupăm de asigurarea unei baze solide pentru un gazon sănătos și viguros. 
					Pe lângă plantare, oferim și servicii complete de întreținere a gazonului. 
					Echipa noastră vă va ajuta să mențineți un gazon verde și sanatos prin tăierea regulată a ierbii, fertilizarii, controlul buruienilor și tratamente împotriva dăunătorilor.`,
			image: LawnGarden,
			title: "Plantare gazon și întreținere",
			key: "serviciu_4",
		},
		serviciu_5: {
			text: `Vom lucra împreună cu dumneavoastră pentru a selecta speciile de plante potrivite în funcție de climă, condițiile de sol și preferințele dumneavoastră.
					Fie că doriți un gard viu cu arbusti sau plante cățărătoare, vom asigura o plantare corectă și uniformă.
					. Pe lângă plantare, putem oferi și servicii de întreținere a gardurilor vii. 
					Acestea pot include tăierea periodică pentru a menține forma și densitatea gardului, îndepărtarea buruienilor și tratarea plantelelor împotriva bolilor și dăunătorilor.`,
			image: BushService,
			title: "Plantare garduri vii",
			key: "serviciu_5",
		},
		serviciu_6: {
			text: `Suntem aici pentru a vă ajuta să mențineți aceste zone într-o stare frumoasă, curată și bine întreținută, creând astfel un mediu plăcut și sigur. Echipa noastră în îngrijirea spațiilor verzi are experiență în gestionarea și întreținerea diverselor tipuri de medii verzi. Indiferent dacă este vorba despre o grădină de apartament, un parc public sau zonele verzi din jurul clădirilor comerciale, vom oferi servicii personalizate pentru a ne adapta nevoilor specifice ale fiecărui proiect. Activitățile noastre de îngrijire includ tăierea și tunsul ierbii, tăierea și modelarea arbustilor, îndepărtarea buruienilor, fertilizarea solului, tratarea plantelor împotriva bolilor și dăunătorilor, precum și curățenia generală a spațiilor. Vom asigura un program regulat de întreținere, astfel încât spațiile dumneavoastră verzi să arate mereu bine îngrijite. Pe lângă îngrijirea ierburilor și plantelor, ne ocupăm și de întreținerea trotuarelor din zona dumneavoastră. Vom asigura curățenia și igiena acestor zone, eliminând murdăria, frunzele căzute, zăpada sau gheața în sezonul rece și menținându-le într-o stare sigură și accesibilă.`,
			image: CleaningSpaces,
			title: "Îngrijire spații verzi, parcuri, trotuare",
			key: "serviciu_6",
		},
	};

	const [buttonsData, setButtonData] = useState(buttonsArray);
	const [activeService, setActiveService] = useState(
		urlService === null ? servicesInfo["serviciu_1"] : servicesInfo[urlService]
	);

	const clickHandler = (position: number, key: string) => {
		setButtonData((prevArr) => {
			const newButtonDataArr = prevArr.map((button, index) => {
				if (position === index) {
					return {
						key: button.key,
						isSelected: true,
						icon: button.icon,
						text: button.text,
					};
				} else {
					return {
						key: button.key,
						isSelected: false,
						icon: button.icon,
						text: button.text,
					};
				}
			});

			return newButtonDataArr;
		});
		setActiveService(servicesInfo[key]);

		const descriptionElement = descriptionRefs.current[position];
		if (descriptionElement) {
			descriptionElement.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	};

	return (
		<>
			<HelmetContainer
				pageTitle="RDA | Amenajare Spații Verzi | Servicii"
				pageDescription="RDA GreenLand îți propune soluții complete pentru crearea unui spațiu verdere nou, sau pentru întreținerea celui existent! Prezentată este lista serviciilor noastre."
			/>

			<S.ServicesContainer>
				<PageHeader
					image={CleanGarden}
					subtitle={activeService.title}
					title="Servicii"
				/>
				<S.ServiceContentWrapped>
					<S.ButtonsContainer>
						{buttonsData.map((button, index) => (
							<div key={index + button.key}>
								<ServiceButton
									icon={button.icon}
									title={button.text}
									isButtonActive={button.isSelected}
									clickHandler={() => {
										clickHandler(index, button.key);
									}}
								/>
								{width < 768 && button.key === activeService.key && (
									<ForwardedServiceDescription
										ref={(el: HTMLDivElement | null) =>
											(descriptionRefs.current[index] = el)
										}
										title={activeService.title}
										description={activeService.text}
										image={activeService.image}
									/>
								)}
							</div>
						))}
					</S.ButtonsContainer>
					{width >= 768 && (
						<ServiceDescription
							title={activeService.title}
							description={activeService.text}
							image={activeService.image}
						/>
					)}
				</S.ServiceContentWrapped>
			</S.ServicesContainer>
		</>
	);
};

type dataType = {
	[key: string]: { text: string; image: string; title: string; key: string };
};
