import React from "react";
import * as S from "./JobsSection.styles";
import {
	BlueGlovesIcon,
	BushIcon,
	FurtunIcon,
	GardenScissorsIcon,
	GreenGloveIcon,
	LawnMowerIcon,
	ShovellIcon,
	TreeIcon,
} from "../../../assets/icons";
import { GridCell } from "../../molecules";
import { homepageTexts } from "../../../mocks";
export const JobsSection = () => {
	return (
		<S.JobSectionWrapper>
			<S.ImageContainer></S.ImageContainer>
			<S.TitleContainer>
				<S.TitleImages>
					<S.TitleImageContainer>
						<img src={GardenScissorsIcon} alt="garden-scissors" />
					</S.TitleImageContainer>
					<S.TitleImageContainer>
						<img src={ShovellIcon} alt="shovel" />
					</S.TitleImageContainer>
				</S.TitleImages>
				<S.Title>Cu ce ne ocupam?</S.Title>
			</S.TitleContainer>
			{homepageTexts && (
				<S.GridContainer>
					<S.GridItem>
						<GridCell
							to="/servicii?serviciuDeschis=serviciu_6"
							image={BlueGlovesIcon}
							title={homepageTexts?.jobSection[0]?.gridCell?.title as string}
							description={
								homepageTexts?.jobSection[0]?.gridCell?.description as string
							}
						/>
					</S.GridItem>

					<S.GridItem>
						<GridCell
							to="/servicii?serviciuDeschis=serviciu_2"
							image={FurtunIcon}
							title={homepageTexts?.jobSection[1]?.gridCell?.title as string}
							description={
								homepageTexts?.jobSection[1]?.gridCell?.description as string
							}
						/>
					</S.GridItem>
					<S.GridItem>
						<GridCell
							to="/servicii?serviciuDeschis=serviciu_1"
							image={GreenGloveIcon}
							title={homepageTexts?.jobSection[2]?.gridCell?.title as string}
							description={
								homepageTexts?.jobSection[2]?.gridCell?.description as string
							}
						/>
					</S.GridItem>
					<S.GridItem>
						<GridCell
							to="/servicii?serviciuDeschis=serviciu_3"
							image={TreeIcon}
							title={homepageTexts?.jobSection[3]?.gridCell?.title as string}
							description={
								homepageTexts?.jobSection[3]?.gridCell?.description as string
							}
						/>
					</S.GridItem>
					<S.GridItem>
						<GridCell
							to="/servicii?serviciuDeschis=serviciu_4"
							image={LawnMowerIcon}
							title={homepageTexts?.jobSection[4]?.gridCell?.title as string}
							description={
								homepageTexts?.jobSection[4]?.gridCell?.description as string
							}
						/>
					</S.GridItem>
					<S.GridItem>
						<GridCell
							to="/servicii?serviciuDeschis=serviciu_5"
							image={BushIcon}
							title={homepageTexts?.jobSection[5]?.gridCell?.title as string}
							description={
								homepageTexts?.jobSection[5]?.gridCell?.description as string
							}
						/>
					</S.GridItem>
				</S.GridContainer>
			)}
		</S.JobSectionWrapper>
	);
};
