import React, { FC, useEffect } from "react";
import * as S from "./About.styles";
import { HelmetContainer, PageHeader } from "../../molecules";
import {
	CleanGarden,
	CurteBloc,
	Gradina,
	MotoScarificator,
	TractorPhoto,
} from "../../../assets/images";
import { PhotoTextSection } from "../../organisms";
import { aboutData } from "../../../mocks";

export const About: FC = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<HelmetContainer
				pageTitle="RDA | Amenajare Spații Verzi - Despre noi"
				pageDescription="RDA GreenLand îți propune soluții complete pentru crearea unui spațiu verdere nou, sau pentru întreținerea celui existent! Nu ezitați a ne contacta."
			/>

			<S.AboutContainer>
				<PageHeader
					subtitle={aboutData.aboutSubtitle}
					title={aboutData.aboutTitle}
					image={CleanGarden}
				/>
				<PhotoTextSection
					reverseSection={false}
					isCentered={false}
					title={aboutData.section.title}
					list={aboutData.section.list}
					hasNumbers={false}
					images={[
						{ image: `${MotoScarificator}`, isLandscape: false },
						{ image: `${CurteBloc}`, isLandscape: true },
						{ image: `${Gradina}`, isLandscape: false },
						{ image: `${TractorPhoto}`, isLandscape: false },
					]}
				/>
			</S.AboutContainer>
		</>
	);
};
