import styled from "styled-components/macro";
import {
	mediaQueries,
	reponsiveFontSize,
	screenSizes,
} from "../../../../utils";

export const NavLinksContainer = styled("div")`
	position: relative;
	display: flex;
	align-items: center;
	width: 60%;
	height: 100%;
	gap: 2rem;
	${() => reponsiveFontSize(screenSizes.laptopL)};
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	padding: 0;
	padding-left: 11rem;
	letter-spacing: -1px;
	a {
		transition: transform 0.5s cubic-bezier(0.7, -1.04, 0.77, 1.44);
		color: ${(props) => props.theme.colors.white};
		text-decoration: none;
		&:hover {
			transform: scale(1.1);
		}
	}

	&::before {
		content: "";
		position: absolute;
		border-left: 10rem solid transparent;
		border-right: 10rem solid transparent;
		border-bottom: 10rem solid
			${(props) => props.theme.colors.mainBackgroundColor};
		left: -10rem;
		top: 0;
	}
	&::after {
		content: "";
		position: absolute;
		top: 1rem;
		left: 5rem;
		width: 13rem;
		background: transparent;
		box-shadow: -20px 75px 10px 4px
			${(props) => props.theme.colors.primaryGreen};
		-webkit-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-o-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
	@media ${mediaQueries.laptop} {
		padding-left: 8rem;
	}
`;
