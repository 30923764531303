import React from "react";
import * as S from "./Footer.styles";
import { LeafIcon } from "../../../assets/icons";
import { ContactWidget } from "../Header/ContactWidget/ContactWidget";
import { Link } from "react-router-dom";
import { navlinks, footerData } from "../../../mocks";
export const Footer = () => {
	return (
		<S.FooterWrapper>
			<S.FooterUpperSection>
				<S.ImageContainer>
					<img src={LeafIcon} alt="leaf" />
				</S.ImageContainer>

				<S.NavLinksContainer>
					{navlinks.map((item, index) => {
						return (
							<Link to={item.path} key={index + item.path}>
								{item.label}
							</Link>
						);
					})}
				</S.NavLinksContainer>

				<ContactWidget />
			</S.FooterUpperSection>
			<S.SecondFooterContainer>
				<h2>{footerData.contact}</h2>
				<br />
				<p>{footerData.copyright}</p>
				<p>{footerData.author}</p>
			</S.SecondFooterContainer>
		</S.FooterWrapper>
	);
};
