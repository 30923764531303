import React, { FC } from "react";
import * as S from "./PhotoTextSection.styles";
import { ImageCard, ImageGallery, TextBlock } from "../../molecules";

export const PhotoTextSection: FC<PhotoTextSectionProps> = ({
	description,
	images,
	title,
	isCentered,
	list,
	reverseSection,
	hasNumbers,
}) => {
	return (
		<S.SectionWrapper>
			<S.PhotoTextSectionContainer reverseSection={reverseSection}>
				<TextBlock
					isCentered={isCentered}
					title={title}
					description={description}
					list={list}
					hasNumbers={hasNumbers}
				/>
				<S.ImagesContainer>
					<ImageGallery reverseSection={reverseSection}>
						{images.map((img, index) => (
							<ImageCard
								key={`${index}_${img.image}`}
								image={img.image}
								isLandscape={img.isLandscape}
							/>
						))}
					</ImageGallery>
				</S.ImagesContainer>
			</S.PhotoTextSectionContainer>
		</S.SectionWrapper>
	);
};

type PhotoTextSectionProps = {
	isCentered?: boolean;
	title: string;
	description?: string;
	images: { image: string; isLandscape: boolean }[];
	list?: string[];
	reverseSection: boolean;
	hasNumbers: boolean;
};
