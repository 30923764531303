import React from "react";
import { Helmet } from "react-helmet-async";

export const HelmetContainer = ({ pageDescription, pageTitle }: Props) => {
	return (
		<Helmet>
			<title>{pageTitle}</title>
			<meta name="description" content={pageDescription} />
		</Helmet>
	);
};

type Props = {
	pageTitle: string;
	pageDescription: string;
};
