import styled from "styled-components/macro";
import { mediaQueries } from "../../../utils";

interface IGalleryContainer {
	reverseSection: boolean;
}

export const ImageGalleryContainer = styled("div")<IGalleryContainer>`
	position: absolute;
	@media ${mediaQueries.mobileL} {
		display: none;
	}
	div:nth-of-type(1) {
		position: absolute;
		top: 25rem;
		left: ${(props) => (props.reverseSection ? "25rem" : "35rem")};
		@media ${mediaQueries.laptopL} {
			left: ${(props) => (props.reverseSection ? "13rem" : "23rem")};
		}
		@media ${mediaQueries.laptop} {
			left: ${(props) => (props.reverseSection ? "5rem" : "10rem")};
		}
		@media ${mediaQueries.tablet} {
			top: 2rem;
			left: 5rem;
		}
	}
	div:nth-of-type(2) {
		position: absolute;
		top: 40rem;
		left: ${(props) => (props.reverseSection ? "5rem" : "15rem")};
		@media ${mediaQueries.laptopL} {
			left: ${(props) => (props.reverseSection ? "0rem" : "5rem")};
		}
		@media ${mediaQueries.laptop} {
			top: 50rem;
			left: ${(props) => (props.reverseSection ? "-2rem" : "5rem")};
		}
		@media ${mediaQueries.tablet} {
			top: 12rem;
			left: -10rem;
		}
	}
	div:nth-of-type(3) {
		position: absolute;
		top: 30rem;
		z-index: 1;
		left: ${(props) => (props.reverseSection ? "25rem" : "8rem")};
		@media ${mediaQueries.laptopL} {
			top: 15rem;
			left: ${(props) => (props.reverseSection ? "13rem" : "12rem")};
		}
		@media ${mediaQueries.laptop} {
			top: 10rem;
			left: ${(props) => (props.reverseSection ? "5rem" : "14rem")};
		}
		@media ${mediaQueries.tablet} {
			display: none;
		}
	}
	div:nth-of-type(4) {
		position: absolute;
		top: 45rem;
		left: ${(props) => (props.reverseSection ? "25rem" : "40rem")};
		@media ${mediaQueries.laptopL} {
			left: ${(props) => (props.reverseSection ? "13rem" : "20rem")};
			z-index: 4;
			top: 55rem;
		}
		@media ${mediaQueries.laptop} {
			top: 35rem;
			left: ${(props) => (props.reverseSection ? "5rem" : "14rem")};
		}
		@media ${mediaQueries.tablet} {
			display: none;
		}
	}
`;
