import React, { FC } from "react";
import * as S from "./TextBlock.styles";
import { LeafIcon } from "../../../assets/icons";

export const TextBlock: FC<TextBlockProps> = ({
	description,
	title,
	isCentered = false,
	list,
	hasNumbers,
}) => {
	const createList = () => {
		if (list) {
			const newList = list?.map((item, index) => {
				if (hasNumbers) {
					return (
						<p key={`${index}_${item}`}>
							{index + 1}. {item}
						</p>
					);
				} else {
					return <p key={`${index}_${item}`}>{item}</p>;
				}
			});
			return newList;
		}
		return null;
	};
	return (
		<S.TextBlockContainer isCentered={isCentered}>
			<S.IconContainer isCentered={isCentered}>
				<img src={LeafIcon} alt="text-block-icon" />
			</S.IconContainer>
			<S.Title>{title}</S.Title>
			{description ? (
				<S.Description>{description}</S.Description>
			) : (
				<S.Description>{createList()}</S.Description>
			)}
		</S.TextBlockContainer>
	);
};

type TextBlockProps = {
	title: string;
	description?: string;
	isCentered?: boolean;
	list?: string[];
	hasNumbers: boolean;
};
