import React, { FC } from "react";
import { ThemeProvider } from "styled-components";

export const theme: ThemeProps = {
	colors: {
		primaryGreen: "#267000",
		mainBackgroundColor: "#DEDED4",
		white: "#FFFFFF",
		black: "#000000",
		darkYellow: "#BBA979",
		gray: "#525252",
	},
	font: {
		fuzzyBubbles: '"Fuzzy Bubbles",  cursive',
	},
	fontSizes: {
		xsmall: "0.8rem",
		small: "1rem",
		medium: "1.5rem",
		xmedium: "2.2rem",
		large: "3rem",
		xlarge: "4.5rem",
		extraLarge: "7rem",
	},
};

const Theme: FC<any> = ({ children }) => (
	<ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export interface ThemeProps {
	colors: {
		primaryGreen: string;
		mainBackgroundColor: string;
		white: string;
		black: string;
		darkYellow: string;
		gray: string;
	};
	font: {
		fuzzyBubbles: string;
	};
	fontSizes: {
		xsmall: string;
		small: string;
		medium: string;
		xmedium: string;
		large: string;
		xlarge: string;
		extraLarge: string;
	};
}

export default Theme;
