import styled from "styled-components/macro";

export const AboutContainer = styled("div")`
	width: 100%;

	div:nth-of-type(2) {
		div {
			div {
				div:nth-of-type(2) {
					font-weight: normal;
					line-height: normal;
					display: flex;
					flex-direction: column;
					gap: 1rem;
					font-size: ${(props) => props.theme.fontSizes.medium};
				}
			}
		}
	}
`;
