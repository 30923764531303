import styled from "styled-components/macro";
import { mediaQueries } from "../../../utils";

interface IImageFlipCart {
	flipped: boolean;
}

export const CardContainer = styled("div")`
	perspective: 100rem;
	width: 33.75rem;
	height: 37.5rem;
	border-radius: 10px;
	cursor: pointer;
	@media ${mediaQueries.tablet} {
		width: 30rem;
		height: 33.75rem;
	}
	@media ${mediaQueries.mobileL} {
		width: 20.5rem;
		height: 22.5rem;
	}
`;

export const Card = styled("div")<IImageFlipCart>`
	position: relative;
	border-radius: 10px;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
	transition: transform 1s cubic-bezier(0.15, -0.04, 0, 1.73);
	transform: ${(props) => (props.flipped ? "rotateY(180deg)" : "rotateY(0)")};
`;

export const CardFront = styled("div")`
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	border-radius: 10px;
	img {
		aspect-ratio: 0.9;
		width: 100%;
		height: 100%;
		border-radius: 10px;
		box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
		position: relative;
	}
	&::before {
		content: "Înainte";
		position: absolute;
		font-size: ${(props) => props.theme.fontSizes.xlarge};
		font-family: ${(props) => props.theme.font.fuzzyBubbles};
		color: ${(props) => props.theme.colors.white};
		z-index: 1;
		font-weight: bold;
		text-align: center;
		padding-top: 3rem;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		@media ${mediaQueries.mobileL} {
			font-size: ${(props) => props.theme.fontSizes.xmedium};
		}
	}
`;

export const CardBack = styled("div")`
	position: absolute;
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	border-radius: 10px;
	transform: rotateY(180deg);
	img {
		aspect-ratio: 0.9;
		width: 100%;
		height: 100%;
		border-radius: 10px;
		box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
	}
	&::before {
		content: "După";
		position: absolute;
		font-size: ${(props) => props.theme.fontSizes.xlarge};
		font-family: ${(props) => props.theme.font.fuzzyBubbles};
		color: ${(props) => props.theme.colors.white};
		z-index: 1;
		font-weight: bold;
		text-align: center;
		padding-top: 3rem;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		@media ${mediaQueries.mobileL} {
			font-size: ${(props) => props.theme.fontSizes.xmedium};
		}
	}
`;
