import styled from "styled-components/macro";
import { CenteredDivOnBothAxis, mediaQueries } from "../../../utils";

interface IImageProps {
	imageUrl: string;
}

export const PageHeaderContainer = styled("div")`
	position: relative;
	z-index: 1;
	height: 25rem;
	@media ${mediaQueries.laptop} {
		height: 20rem;
	}
	@media ${mediaQueries.mobileL} {
		height: 15rem;
	}
`;

export const ImageContainer = styled("div")<IImageProps>`
	position: absolute;
	height: 25rem;
	width: 100%;
	background: url(${(props) => props.imageUrl});
	background-repeat: no-repeat;
	background-position: bottom 15rem right 50%;
	background-attachment: fixed;
	background-size: cover;
	z-index: -2;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.7);
		backdrop-filter: blur(8px);
	}

	@media ${mediaQueries.laptop} {
		height: 20rem;
		background-position: bottom 20rem right 50%;
	}
	@media ${mediaQueries.mobileL} {
		height: 15rem;
		background-position: bottom 30rem right 50%;
	}
`;

export const TitleContainer = styled(CenteredDivOnBothAxis)`
	flex-direction: column;
	padding-top: 3rem;
	text-align: center;
	font-size: ${(props) => props.theme.fontSizes.xmedium};
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	h1 {
		color: ${(props) => props.theme.colors.white};
	}
	h2 {
		color: ${(props) => props.theme.colors.primaryGreen};
	}
	@media ${mediaQueries.laptop} {
		font-size: ${(props) => props.theme.fontSizes.medium};
	}
`;
