import styled from "styled-components/macro";
import { Gradina } from "../../../assets/images";
import { CenteredDivOnBothAxis, mediaQueries } from "../../../utils";

export const JobSectionWrapper = styled("div")`
	position: relative;
	display: flex;
	align-items: center;
	gap: 5rem;
	flex-direction: column;
	height: 90rem;
	width: 100%;
	@media ${mediaQueries.tablet} {
		height: 110rem;
		gap: 2rem;
	}
	@media ${mediaQueries.mobileM} {
		height: 130rem;
	}
	@media ${mediaQueries.mobileS} {
		height: 160rem;
	}
`;
export const ImageContainer = styled("div")`
	position: absolute;
	height: 100%;
	width: 100%;
	background: url(${Gradina});
	background-repeat: no-repeat;
	background-position: center top;
	background-attachment: fixed;
	background-size: cover;
	z-index: -2;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.3);
		backdrop-filter: blur(5px);
	}
`;

export const TitleContainer = styled(CenteredDivOnBothAxis)`
	flex-direction: column;
	padding-top: 3rem;
`;

export const TitleImages = styled(CenteredDivOnBothAxis)``;

export const TitleImageContainer = styled("div")`
	width: 7rem;
	height: 7rem;
	img {
		width: 100%;
		height: 100%;
		mix-blend-mode: luminosity;
		filter: brightness(0) saturate(100%) grayscale(100%);
	}
	@media ${mediaQueries.tablet} {
		width: 5rem;
		height: 5rem;
	}
`;

export const Title = styled("h2")`
	font-size: ${(props) => props.theme.fontSizes.xlarge};
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	color: ${(props) => props.theme.colors.black};
	font-weight: bold;
	letter-spacing: 1px;
	@media ${mediaQueries.tablet} {
		font-size: ${(props) => props.theme.fontSizes.large};
	}
`;

export const GridContainer = styled("div")`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-rows: repeat(2, 1fr);
	width: 90%;
	margin: 0 auto;
	@media ${mediaQueries.tablet} {
		width: 95%;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(3, 1fr);
	}
	@media ${mediaQueries.mobileM} {
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(6, 1fr);
	}
`;
export const GridItem = styled("div")`
	padding: 0rem 2rem 2rem 2rem;
	&:nth-child(1) {
		border-right: 1px solid ${(props) => props.theme.colors.white};
		border-bottom: 1px solid ${(props) => props.theme.colors.white};
	}
	&:nth-child(2) {
		border-left: 1px solid ${(props) => props.theme.colors.white};
		border-right: 1px solid ${(props) => props.theme.colors.white};
		border-bottom: 1px solid ${(props) => props.theme.colors.white};
	}
	&:nth-child(3) {
		border-left: 1px solid ${(props) => props.theme.colors.white};
		border-bottom: 1px solid ${(props) => props.theme.colors.white};
	}
	&:nth-child(4) {
		border-top: 1px solid ${(props) => props.theme.colors.white};
		border-right: 1px solid ${(props) => props.theme.colors.white};
	}
	&:nth-child(5) {
		border-top: 1px solid ${(props) => props.theme.colors.white};
		border-right: 1px solid ${(props) => props.theme.colors.white};
		border-left: 1px solid ${(props) => props.theme.colors.white};
	}
	&:nth-child(6) {
		border-left: 1px solid ${(props) => props.theme.colors.white};
		border-top: 1px solid ${(props) => props.theme.colors.white};
	}
	@media ${mediaQueries.tablet} {
		padding: 1rem 1rem 1rem 1rem;
		&:nth-child(1) {
			border-right: 1px solid ${(props) => props.theme.colors.white};
			border-bottom: 1px solid ${(props) => props.theme.colors.white};
		}
		&:nth-child(2) {
			border-left: 1px solid ${(props) => props.theme.colors.white};
			border-right: 0px;
			border-bottom: 1px solid ${(props) => props.theme.colors.white};
		}
		&:nth-child(3) {
			border-left: 0px;
			border-right: 1px solid ${(props) => props.theme.colors.white};
			border-top: 1px solid ${(props) => props.theme.colors.white};
		}
		&:nth-child(4) {
			border-top: 1px solid ${(props) => props.theme.colors.white};
			border-right: 0px;
			border-bottom: 1px solid ${(props) => props.theme.colors.white};
			border-left: 1px solid ${(props) => props.theme.colors.white};
		}
		&:nth-child(5) {
			border-top: 1px solid ${(props) => props.theme.colors.white};
			border-right: 1px solid ${(props) => props.theme.colors.white};
			border-left: 0px;
		}
		&:nth-child(6) {
			border-left: 1px solid ${(props) => props.theme.colors.white};
			border-top: 1px solid ${(props) => props.theme.colors.white};
		}
	}
	@media ${mediaQueries.mobileM} {
		&:nth-child(1) {
			border-right: 2px solid ${(props) => props.theme.colors.white};
			border-bottom: 2px solid ${(props) => props.theme.colors.white};
			border-top: 2px solid ${(props) => props.theme.colors.white};
		}
		&:nth-child(2) {
			border-left: 2px solid ${(props) => props.theme.colors.white};
			border-right: 0px;
			border-bottom: 2px solid ${(props) => props.theme.colors.white};
		}
		&:nth-child(3) {
			border-right: 2px solid ${(props) => props.theme.colors.white};
			border-top: 0px;
			border-bottom: 0px;
		}
		&:nth-child(4) {
			border-top: 2px solid ${(props) => props.theme.colors.white};
			border-bottom: 0px;
			border-left: 2px solid ${(props) => props.theme.colors.white};
		}
		&:nth-child(5) {
			border-top: 2px solid ${(props) => props.theme.colors.white};
			border-right: 2px solid ${(props) => props.theme.colors.white};
			border-left: 0px;
		}
		&:nth-child(6) {
			border-left: 2px solid ${(props) => props.theme.colors.white};
			border-top: 2px solid ${(props) => props.theme.colors.white};
			border-bottom: 2px solid ${(props) => props.theme.colors.white};
		}
	}
`;
