import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Footer, Header } from "../components/molecules";
import { About, Gallery, Home, Services } from "../components/pages";

export const AppRouting = () => {
	return (
		<Router>
			<Header />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/despre-noi" element={<About />} />
				<Route path="/servicii" element={<Services />} />
				<Route path="/galerie" element={<Gallery />} />
			</Routes>
			<Footer />
		</Router>
	);
};
