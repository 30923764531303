import React, { FC, useState } from "react";
import * as S from "./ImageFlipCard.styles";

export const ImageFlipCard: FC<ImageFlipCardProps> = ({ before, after }) => {
	const [flipped, setFlipped] = useState(false);

	const handleFlip = () => {
		setFlipped(!flipped);
	};

	return (
		<S.CardContainer>
			<S.Card flipped={flipped} onClick={handleFlip}>
				<S.CardFront>
					<img src={before} alt="card-front" />
				</S.CardFront>
				<S.CardBack>
					<img src={after} alt="card-back" />
				</S.CardBack>
			</S.Card>
		</S.CardContainer>
	);
};

type ImageFlipCardProps = {
	before: string;
	after: string;
};
