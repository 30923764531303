import { FC } from "react";
import { useTheme } from "styled-components";
import { ThemeProps } from "../../../../Theme";
import { Facebook, Instagram, Phone, WhatsApp } from "../../../../assets/icons";
import * as S from "./ContactWidget.styles";
import { contactData } from "../../../../mocks";

export const ContactWidget: FC = () => {
	const theme = useTheme() as ThemeProps;
	return (
		<S.ContactWidgetContainer>
			<S.PhoneContainer>
				<Phone color={theme.colors.white} height="16" width="16" />{" "}
				<span>{contactData.phoneNumber}</span>
			</S.PhoneContainer>
			<S.SocialMediaLinksContainer>
				<a href={contactData.instagramLink} target="_blank" rel="noreferrer">
					<Instagram color={theme.colors.white} height="16" width="16" />
				</a>
				<a href={contactData.facebookLink} target="_blank" rel="noreferrer">
					<Facebook color={theme.colors.white} height="16" width="16" />
				</a>
				<a href={contactData.whatsUpLink} target="_blank" rel="noreferrer">
					<WhatsApp color={theme.colors.white} height="16" width="16" />
				</a>
			</S.SocialMediaLinksContainer>
		</S.ContactWidgetContainer>
	);
};
