import React, { FC } from "react";
import { IconProps } from "../../Interfaces";

export const Phone: FC<IconProps> = ({ width, height, color }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22 20.736L17.1585 14.5072C17.1475 14.5109 14.4442 15.3964 14.3204 15.4339C11.2403 16.4294 4.97152 8.2656 7.98285 7.18943L10.8471 6.24893L6.04405 0L3.14822 0.952419C-6.75481 4.39451 8.96876 24.7693 19.0987 21.6829C19.265 21.6325 21.989 20.7396 22 20.736Z"
				fill={color}
			/>
		</svg>
	);
};
