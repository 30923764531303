import styled from "styled-components/macro";
import { CenteredDivOnBothAxis } from "../../../../utils";

export const ContactWidgetContainer = styled(CenteredDivOnBothAxis)`
	flex-direction: column;
	width: 20%;
	gap: 1rem;
`;

export const PhoneContainer = styled(CenteredDivOnBothAxis)`
	gap: 0.5rem;
	padding: 0 2rem;
	padding-bottom: 0.5rem;
	font-size: ${(props) => props.theme.fontSizes.medium};
	color: ${(props) => props.theme.colors.white};
	border-bottom: 1px solid ${(props) => props.theme.colors.white};
`;

export const SocialMediaLinksContainer = styled(CenteredDivOnBothAxis)`
	gap: 0.5rem;
`;
