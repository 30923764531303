import styled from "styled-components/macro";
import { mediaQueries } from "../../../utils";

export const ContactSectionContainer = styled("div")`
	position: relative;
	width: 100%;
	height: 90rem;
	background-color: ${(props) => props.theme.colors.mainBackgroundColor};
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 3rem;
	z-index: 5;

	&::before {
		content: "";
		z-index: 4;
		width: 100%;
		height: 30rem;
		background-color: ${(props) => props.theme.colors.primaryGreen};
		position: absolute;
		border-left: 50rem solid transparent;
		border-right: 50rem solid transparent;
		border-bottom: 30rem solid
			${(props) => props.theme.colors.mainBackgroundColor};
		top: 0;
	}
	@media ${mediaQueries.laptop} {
		&::before {
			height: 25rem;
			position: absolute;
			border-left: 35rem solid transparent;
			border-right: 35rem solid transparent;
			border-bottom: 25rem solid
				${(props) => props.theme.colors.mainBackgroundColor};
		}
	}
	@media ${mediaQueries.tablet} {
		height: 70rem;
		gap: 1.5rem;
		&::before {
			height: 15rem;
			position: absolute;
			border-left: 20rem solid transparent;
			border-right: 20rem solid transparent;
			border-bottom: 15rem solid
				${(props) => props.theme.colors.mainBackgroundColor};
		}
	}
	@media ${mediaQueries.mobileL} {
		&::before {
			height: 10rem;
			position: absolute;
			border-left: 8rem solid transparent;
			border-right: 8rem solid transparent;
			border-bottom: 8rem solid
				${(props) => props.theme.colors.mainBackgroundColor};
		}
	}
	@media ${mediaQueries.mobileM} {
		height: 40rem;
		gap: 3rem;
	}
`;

export const Title = styled("h1")`
	position: relative;
	z-index: 10;
	font-size: ${(props) => props.theme.fontSizes.xlarge};
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	color: ${(props) => props.theme.colors.black};
	letter-spacing: 2px;
	text-align: center;
	@media ${mediaQueries.laptop} {
		font-size: ${(props) => props.theme.fontSizes.large};
	}
	@media ${mediaQueries.mobileM} {
		font-size: ${(props) => props.theme.fontSizes.xmedium};
	}
`;

export const Description = styled("p")`
	position: relative;
	z-index: 10;
	font-size: ${(props) => props.theme.fontSizes.large};
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	color: ${(props) => props.theme.colors.black};
	text-align: center;
	width: 80%;
	margin: 0 auto;
	@media ${mediaQueries.laptop} {
		font-size: ${(props) => props.theme.fontSizes.xmedium};
	}
	@media ${mediaQueries.mobileM} {
		font-size: ${(props) => props.theme.fontSizes.medium};
	}
`;

export const Button = styled("a")`
	position: relative;
	z-index: 10;
	text-decoration: none;
	font-size: ${(props) => props.theme.fontSizes.large};
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	padding: 2.5rem 6rem;
	background-color: ${(props) => props.theme.colors.primaryGreen};
	color: ${(props) => props.theme.colors.white};
	font-weight: bold;
	border: none;
	cursor: pointer;
	text-align: center;
	box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
		rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
	@media ${mediaQueries.laptop} {
		padding: 2rem 4rem;
	}
	@media ${mediaQueries.mobileM} {
		font-size: ${(props) => props.theme.fontSizes.medium};
		padding: 1.5rem 3rem;
	}
`;
