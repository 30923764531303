import styled from "styled-components/macro";
import { mediaQueries } from "../../../utils";

export const ServicesContainer = styled("div")`
	width: 100%;
	min-height: 90vh;
	background-color: ${(props) => props.theme.colors.mainBackgroundColor};
	transition: all 0.5s ease;
`;

export const ServiceContentWrapped = styled("div")`
	padding: 5rem 0;
	width: 80%;
	margin: 0 auto;
	display: flex;
	gap: 5rem;
	@media ${mediaQueries.laptop} {
		width: 95%;
	}
	@media ${mediaQueries.tablet} {
		flex-direction: column;
	}
`;

export const ButtonsContainer = styled("div")`
	display: flex;
	flex-direction: column;
	gap: 5rem;
	@media ${mediaQueries.laptop} {
		gap: 3rem;
	}
`;
