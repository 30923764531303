import styled from "styled-components/macro";
import { CenteredDivOnBothAxis, mediaQueries } from "../../../../utils";

export const LogoContainer = styled(CenteredDivOnBothAxis)`
	position: relative;
	background-color: ${(props) => props.theme.colors.mainBackgroundColor};
	height: 100%;
	width: 20%;
	a {
		height: 8rem;
		width: 8rem;
		text-decoration: none;
		img {
			position: relative;
			height: 100%;
			width: 100%;
			z-index: 2;
		}
	}

	@media ${mediaQueries.tablet} {
		a {
			height: 5rem;
			width: 5rem;
		}
	}
`;
