import styled from "styled-components/macro";

interface ISideBarProps {
	cssShowSideBar: boolean;
}

export const BackDrop = styled("div")<ISideBarProps>`
	position: absolute;
	width: 100%;
	height: 100%;
	background: ${(props) => props.theme.colors.primaryGreen};
	bottom: 0;
	top: 6rem;
	transition: opacity 0.5s ease;
	opacity: ${(props) => (props.cssShowSideBar ? "0.6" : "0")};
	display: ${(props) => (props.cssShowSideBar ? "blcok" : "none")};
	z-index: 4;
`;

export const SideBarContainer = styled("div")<ISideBarProps>`
	position: fixed;
	transition: all 1s cubic-bezier(0.88, -0.54, 0.64, 1.6);
	right: 0;
	top: 6rem;
	height: ${(props) => (props.cssShowSideBar ? "100%" : "20%")};
	width: 25rem;
	transform: ${(props) =>
		props.cssShowSideBar ? "translateX(0rem)" : "translateX(25rem)"};
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	background-color: ${(props) => props.theme.colors.primaryGreen};
	gap: 3rem;
	font-size: ${(props) => props.theme.fontSizes.medium};
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	padding: 2rem;
	letter-spacing: -1px;
	z-index: 10;
	box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
		rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;

	a {
		color: ${(props) => props.theme.colors.white};
		padding-bottom: 0.5rem;
		text-decoration: none;
		border-bottom: 1px solid rgba(255, 255, 255, 0.5);
		width: 100%;
	}
	span {
		color: ${(props) => props.theme.colors.white};
		text-align: center;
		position: absolute;
		top: 87rem;
		width: 85%;
		margin: 0 auto;
		font-weight: 600;
		font-size: ${(props) => props.theme.fontSizes.small};
		letter-spacing: 0px;
	}
`;
