import { FC } from "react";
import { LogoPNG } from "../../../../assets/images";
import * as S from "./Logo.styles";
import { Link } from "react-router-dom";

export const Logo: FC = () => {
	return (
		<S.LogoContainer>
			<Link to="/">
				<img src={LogoPNG} alt="png-logo" />
			</Link>
		</S.LogoContainer>
	);
};
