import React, { FC } from "react";
import * as S from "./ImageGallery.styles";

export const ImageGallery: FC<ImageGalleryProps> = ({
	children,
	reverseSection,
}) => {
	return (
		<S.ImageGalleryContainer reverseSection={reverseSection}>
			{children}
		</S.ImageGalleryContainer>
	);
};

type ImageGalleryProps = {
	children: any;
	reverseSection: boolean;
};
