import React, { FC } from "react";
import * as S from "./HamburgerButton.styles";
export const HamburgerButton: FC<HamburgerButtonProps> = ({
	setShowSideBar,
	showSideBar,
	hamburgerButtonRef,
}) => {
	return (
		<S.BurgerContainer
			ref={hamburgerButtonRef}
			onClick={() => {
				setShowSideBar(!showSideBar);
			}}
		>
			<S.Line1 cssShowSideBar={showSideBar}></S.Line1>
			<S.Line2 cssShowSideBar={showSideBar}></S.Line2>
			<S.Line3 cssShowSideBar={showSideBar}></S.Line3>
		</S.BurgerContainer>
	);
};

export type HamburgerButtonProps = {
	setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>;
	showSideBar: boolean;
	hamburgerButtonRef: React.RefObject<HTMLDivElement>;
};
