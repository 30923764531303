import React, { FC, useEffect } from "react";
import * as S from "./Gallery.styles";
import { HelmetContainer, ImageFlipCard, PageHeader } from "../../molecules";
import {
	After_1,
	After_2,
	After_3,
	After_4,
	After_5,
	After_6,
	After_7,
	Before_1,
	Before_2,
	Before_3,
	Before_4,
	Before_5,
	Before_6,
	Before_7,
	CleanGarden,
} from "../../../assets/images";
export const Gallery: FC = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<HelmetContainer
				pageTitle="RDA | Amenajare Spații Verzi - Galerie"
				pageDescription="RDA va prezintă câteva din lucrările executate, având oportunitatea de a observă dintre înainte și după finalizarea lucrării"
			/>
			<S.GalleryPageContainer>
				<PageHeader image={CleanGarden} subtitle={"Galerie"} title="Galerie" />
				<S.ImagesContainer>
					<ImageFlipCard before={Before_1} after={After_1} />
					<ImageFlipCard before={Before_2} after={After_2} />
					<ImageFlipCard before={Before_3} after={After_3} />
					<ImageFlipCard before={Before_4} after={After_4} />
					<ImageFlipCard before={Before_5} after={After_5} />
					<ImageFlipCard before={Before_6} after={After_6} />
					<ImageFlipCard before={Before_7} after={After_7} />
				</S.ImagesContainer>
			</S.GalleryPageContainer>
		</>
	);
};
