import React, { FC } from "react";
import * as S from "./GridCell.styles";

export const GridCell: FC<GridCellType> = ({
	description,
	image,
	title,
	to,
}) => {
	return (
		<S.GridCellContainer to={to}>
			<S.IconContainer>
				<img src={image} alt="grid-cell" />
			</S.IconContainer>
			<S.Title>{title}</S.Title>
			<S.Description>{description}</S.Description>
		</S.GridCellContainer>
	);
};

type GridCellType = {
	image: string;
	title: string;
	description: string;
	to: string;
};
