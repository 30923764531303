import styled from "styled-components/macro";
import { mediaQueries } from "../../../utils";

interface IServiceButton {
	isSelected: boolean;
}

export const ServiceButtonContainer = styled("div")<IServiceButton>`
	display: flex;
	align-items: center;
	justify-items: center;
	background-color: ${(props) =>
		props.isSelected ? props.theme.colors.white : props.theme.colors.gray};
	width: 43rem;
	min-height: 8rem;
	height: auto;
	padding: 0.5rem 2rem;
	gap: 2rem;
	box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
		rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
	@media ${mediaQueries.laptop} {
		gap: 0.5rem;
		width: 30rem;
		padding: 0rem 1rem;
	}
	@media ${mediaQueries.tablet} {
		width: 90vw;
		gap: 2rem;
		padding: 0.5rem 2rem;
		justify-content: space-between;
	}
	@media ${mediaQueries.mobileL} {
		gap: 0.5rem;
		padding: 0rem 2rem;
	}
`;
export const Title = styled("p")<IServiceButton>`
	text-align: center;
	width: 70%;
	font-size: ${(props) => props.theme.fontSizes.xmedium};
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	color: ${(props) =>
		props.isSelected ? props.theme.colors.black : props.theme.colors.white};
	font-weight: bold;

	@media ${mediaQueries.laptop} {
		font-size: ${(props) => props.theme.fontSizes.medium};
	}
	@media ${mediaQueries.tablet} {
		font-size: ${(props) => props.theme.fontSizes.xmedium};
	}
	@media ${mediaQueries.mobileL} {
		font-size: ${(props) => props.theme.fontSizes.medium};
	}
`;
export const IconContainer = styled("div")<IServiceButton>`
	width: 6rem;
	height: 6rem;
	img {
		width: 100%;
		height: 100%;
		filter: grayscale(100%) brightness(200%) contrast(200%);
	}
	@media ${mediaQueries.laptop} {
		width: 4rem;
		height: 4rem;
	}
`;
export const ArrowContainer = styled("div")<IServiceButton>`
	width: 2.5rem;
	height: 4rem;
	img {
		width: 100%;
		height: 100%;
		${(props) =>
			props.isSelected
				? ""
				: "filter: brightness(0) saturate(100%) invert(100%) grayscale(100%)"}
	}
	@media ${mediaQueries.laptop} {
		width: 1.5rem;
		height: 3rem;
	}
`;
