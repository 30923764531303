import styled from "styled-components/macro";
import {
	CenteredDivOnBothAxis,
	mediaQueries,
	reponsiveFontSize,
	screenSizes,
} from "../../../utils";

export const FooterWrapper = styled("div")`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 20rem;
	background-color: ${(props) => props.theme.colors.primaryGreen};
	@media ${mediaQueries.tablet} {
		height: 40rem;
	}
`;

export const ImageContainer = styled("div")`
	width: 12rem;
	height: 10rem;

	img {
		width: 100%;
		height: 100%;
		filter: brightness(0) saturate(100%) invert(100%) grayscale(100%);
	}
	@media ${mediaQueries.tablet} {
		width: 8rem;
		height: 8rem;
		order: 1;
	}
	@media ${mediaQueries.mobileL} {
		width: 6rem;
		height: 6rem;
	}
`;

export const FooterUpperSection = styled(CenteredDivOnBothAxis)`
	width: 100%;
	padding: 0 5rem;
	gap: 5rem;
	div:nth-of-type(3) {
		width: auto;
	}
	@media ${mediaQueries.laptop} {
		padding: 0 2rem;
		gap: 3rem;
	}
	@media ${mediaQueries.tablet} {
		padding: 0 2rem;
		gap: 3rem;
		flex-direction: column;
		div:nth-of-type(3) {
			width: 40%;
			order: 2;
			@media ${mediaQueries.mobileL} {
				width: 65%;
			}
			div:nth-of-type(1) {
				width: 100%;
				border-bottom: 1px solid rgba(255, 255, 255, 0.5);
				span {
					letter-spacing: 1px;
				}
			}
		}
	}
	@media ${mediaQueries.mobileL} {
		gap: 2rem;
	}
`;

export const NavLinksContainer = styled("div")`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 2rem;
	width: 100%;
	padding-bottom: 2rem;
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
	${() => reponsiveFontSize(screenSizes.laptopL)};
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	letter-spacing: -1px;
	a {
		transition: transform 0.5s cubic-bezier(0.7, -1.04, 0.77, 1.44);
		color: ${(props) => props.theme.colors.white};
		text-decoration: none;
		&:hover {
			transform: scale(1.1);
		}
	}
	@media ${mediaQueries.tablet} {
		order: 3;
	}
	@media ${mediaQueries.mobileL} {
		font-size: ${(props) => props.theme.fontSizes.medium};
	}
`;

export const SecondFooterContainer = styled(CenteredDivOnBothAxis)`
	flex-direction: column;
	text-align: center;
	color: ${(props) => props.theme.colors.white};
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	font-size: ${(props) => props.theme.fontSizes.small};
	padding-top: 1rem;
	width: 50%;
	margin: 0 auto;
	@media ${mediaQueries.tablet} {
		padding-top: 3rem;
		width: 90%;
	}
`;
