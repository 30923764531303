import styled from "styled-components/macro";
import { mediaQueries } from "../../../utils";

interface IImageCard {
	isLandscape: boolean;
}

export const ImageCardContainer = styled("div")<IImageCard>`
	position: relative;
	width: ${(props) => (props.isLandscape ? "36rem" : "26rem")};
	height: 24rem;
	z-index: 2;
	img {
		width: 100%;
		height: 100%;
		box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
			rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
	}

	&::after {
		content: "";
		background-color: ${(props) => props.theme.colors.white};
		position: absolute;
		top: -1rem;
		left: -1rem;
		z-index: -1;
		width: ${(props) => (props.isLandscape ? "38rem" : "28rem")};
		height: 26rem;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
	}
	@media ${mediaQueries.laptop} {
		width: ${(props) => (props.isLandscape ? "30rem" : "20rem")};
		height: 18rem;
		&::after {
			width: ${(props) => (props.isLandscape ? "32rem" : "22rem")};
			height: 20rem;
		}
	}
`;
