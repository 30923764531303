import styled, { css } from "styled-components/macro";

export enum screenSizes {
	mobileS = "320px",
	mobileM = "375px",
	mobileL = "425px",
	tablet = "768px",
	laptop = "1024px",
	laptopL = "1440px",
	desktop = "2560px",
}

export const mediaQueries = {
	mobileS: `(max-width: ${screenSizes.mobileS})`,
	mobileM: `(max-width: ${screenSizes.mobileM})`,
	mobileL: `(max-width: ${screenSizes.mobileL})`,
	tablet: `(max-width: ${screenSizes.tablet})`,
	laptop: `(max-width: ${screenSizes.laptop})`,
	laptopL: `(max-width: ${screenSizes.laptopL})`,
	desktop: `(max-width: ${screenSizes.desktop})`,
	desktopL: `(max-width: ${screenSizes.desktop})`,
};

export const reponsiveFontSize = (start?: screenSizes) => {
	switch (start) {
		case screenSizes.laptopL:
			return css`
				font-size: ${(props) => props.theme.fontSizes.large};
				line-height: ${(props) => props.theme.fontSizes.xlarge};

				@media ${mediaQueries.laptop} {
					font-size: ${(props) => props.theme.fontSizes.xmedium};
					line-height: ${(props) => props.theme.fontSizes.xmedium};
				}
			`;
		default:
			return css`
				font-size: ${(props) => props.theme.fontSizes.medium};
				line-height: ${(props) => props.theme.fontSizes.xmedium};

				@media ${mediaQueries.laptop} {
					font-size: ${(props) => props.theme.fontSizes.small};
					line-height: ${(props) => props.theme.fontSizes.small};
				}
			`;
	}
};

export const CenteredDivOnBothAxis = styled("div")`
	display: flex;
	align-items: center;
	justify-content: center;
`;
