import styled from "styled-components/macro";
import { CenteredDivOnBothAxis, mediaQueries } from "../../../utils";

interface IPhotoTextSection {
	reverseSection: boolean;
}

export const SectionWrapper = styled("div")`
	width: 100%;
	height: 90rem;
	background-color: ${(props) => props.theme.colors.mainBackgroundColor};
	@media ${mediaQueries.tablet} {
		height: 100rem;
	}
	@media ${mediaQueries.mobileL} {
		padding-bottom: 3rem;
		height: auto;
	}
`;

export const PhotoTextSectionContainer = styled(
	CenteredDivOnBothAxis
)<IPhotoTextSection>`
	width: 90%;
	height: 100%;
	gap: 5rem;
	margin: 0 auto;
	flex-direction: ${(props) => (props.reverseSection ? "row-reverse" : "row")};
	@media ${mediaQueries.laptopL} {
		gap: 0rem;
	}
	@media ${mediaQueries.laptop} {
		width: 95%;
	}
	@media ${mediaQueries.tablet} {
		flex-direction: column;
		padding-top: 3rem;
	}
`;

export const ImagesContainer = styled("div")`
	position: relative;
	width: 40vw;
	height: 100%;
`;
