import { FC } from "react";
import { navlinks } from "../../../../mocks";
import { Link } from "react-router-dom";
import * as S from "./MobileSideBar.styles";

export const MobileSideBar: FC<MobileSideBarProps> = ({
	sideBarRef,
	showSideBar,
	setShowSideBar,
}) => {
	return (
		<>
			<S.BackDrop cssShowSideBar={showSideBar} />
			<S.SideBarContainer ref={sideBarRef} cssShowSideBar={showSideBar}>
				{navlinks.map((item, index) => {
					return (
						<Link
							onClick={() => {
								setShowSideBar(false);
							}}
							to={item.path}
							key={index + item.path}
						>
							{item.label}
						</Link>
					);
				})}
				<span>© 2023. RDA Green Land. All rights Reserved.</span>
			</S.SideBarContainer>
		</>
	);
};

export type MobileSideBarProps = {
	setShowSideBar: React.Dispatch<React.SetStateAction<boolean>>;
	showSideBar: boolean;
	sideBarRef: React.RefObject<HTMLDivElement>;
};
