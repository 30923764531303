import { FC } from "react";
import { navlinks } from "../../../../mocks";
import { Link } from "react-router-dom";
import * as S from "./NavLinks.styles";

export const NavLinks: FC = () => {
	return (
		<S.NavLinksContainer>
			{navlinks.map((item, index) => {
				return (
					<Link to={item.path} key={index + item.path}>
						{item.label}
					</Link>
				);
			})}
		</S.NavLinksContainer>
	);
};
