import React, { FC } from "react";
import * as S from "./PageHeader.styles";

export const PageHeader: FC<PageHeaderProps> = ({ image, title, subtitle }) => {
	return (
		<S.PageHeaderContainer>
			<S.ImageContainer imageUrl={image} />
			<S.TitleContainer>
				<h2>{title}</h2>
				<h1>{subtitle}</h1>
			</S.TitleContainer>
		</S.PageHeaderContainer>
	);
};

type PageHeaderProps = {
	image: string;
	title: string;
	subtitle: string;
};
