import React, { FC } from "react";
import { IconProps } from "../../Interfaces";

export const Facebook: FC<IconProps> = ({ width, height, color }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 12 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3 8H0V12H3V24H8V12H11.642L12 8H8V6.333C8 5.378 8.192 5 9.115 5H12V0H8.192C4.596 0 3 1.583 3 4.615V8Z"
				fill={color}
			/>
		</svg>
	);
};
