import React, { FC } from "react";
import * as S from "./ServiceButton.styles";
import { Chevron } from "../../../assets/icons";

export const ServiceButton: FC<ServiceButtonProps> = ({
	isButtonActive,
	icon,
	title,
	clickHandler,
}) => {
	return (
		<S.ServiceButtonContainer
			onClick={clickHandler}
			isSelected={isButtonActive}
		>
			<S.IconContainer isSelected={isButtonActive}>
				<img src={icon} alt="green-glove" />
			</S.IconContainer>
			<S.Title isSelected={isButtonActive}>{title} </S.Title>
			<S.ArrowContainer isSelected={isButtonActive}>
				<img src={Chevron} alt="green-glove" />
			</S.ArrowContainer>
		</S.ServiceButtonContainer>
	);
};

type ServiceButtonProps = {
	isButtonActive: boolean;
	title: string;
	icon: string;
	clickHandler: () => void;
};
