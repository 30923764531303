import styled from "styled-components/macro";
import { mediaQueries } from "../../../utils";

export const GalleryPageContainer = styled("div")`
	min-height: 90vh;
	width: 100%;
	background-color: ${(props) => props.theme.colors.mainBackgroundColor};
`;

export const ImagesContainer = styled("div")`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10rem;
	width: 85%;
	margin: 0 auto;
	padding-top: 5rem;
	padding-bottom: 5rem;

	@media ${mediaQueries.mobileL} {
		width: 95%;
	}
`;
